/**
 * @file
 * Styles for Firefox
 */

@import "variables";

@-moz-document url-prefix() { 
  
  // "Continue Shopping" Button in Cart
  #block-block-13 a {
    top: -83px;
  }
  
  // Fix max-height on product displays
  .node-product-display.view-mode-search_result .commerce-product-field-field-images img, .node-flag-display.view-mode-search_result .commerce-product-field-field-images img, .node-apparel-display.view-mode-search_result .commerce-product-field-field-images img {
    max-height: 200px;
  }
}